<template>
	<div class="activies">
		<Form :model="formItem" :label-width="80" ref="formInline" :rules="ruleInline">
			<FormItem :label="'姓名：' | language" prop="name">
				<Input
					v-model="formItem.name"
					style="width:320px;"
					:placeholder="'请输入姓名' | language"
				/>
			</FormItem>
			<FormItem :label="'学号：' | language" prop="num">
				<Input
					v-model="formItem.num"
					style="width:320px;"
					:placeholder="'请输入学号' | language"
				/>
			</FormItem>
			<FormItem :label="'专业：' | language" prop="major">
				<Input
					v-model="formItem.major"
					style="width:320px;"
					:placeholder="'请输入专业' | language"
				/>
			</FormItem>

			<FormItem>
				<Button
					type="primary"
					style="width:320px;"
					size="large"
					@click="handleSubmit('formInline')"
					>{{ '提交' | language }}</Button
				>
			</FormItem>
		</Form>
	</div>
</template>

<script>
import { languageTranslation } from '../../assets/util/language';
export default {
	components: {},
	data() {
		return {
			id: '',
			formItem: {
				name: '',
				num: '',
				major: '',
			},
			ruleInline: {
				name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
				num: [{ required: true, message: '请输入学号', trigger: 'blur' }],
				major: [{ required: true, message: '请输入专业', trigger: 'blur' }],
			},
		};
	},
	mounted() {
		this.id = this.$route.params.id;
		// this.fetchData();
	},
	methods: {
		handleSubmit(name) {
			this.$refs[name].validate(async (valid) => {
				if (valid) {
					let res = await this.$http.post(`/information/eTalk/${this.id}`, {
						name: this.formItem.name,
						code: this.formItem.num,
						major: this.formItem.major,
					});
					this.$Message.success(languageTranslation('预约成功!'));
					this.$router.go(-1);
				} else {
					this.$Message.error('Fail!');
				}
			});
		},
	},
};
</script>

<style lang="less" scoped>
.activies {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>
