<template>
    <div class="detail" v-if="info">
        <div class="item_title">
            <img :src="info.cover.src" alt />
            <div class="item_title_content">
                <div class="item_detail">
                    <div class="item_detail_title">{{ info.title }}</div>
                    <div class="item_detail_reserve">
                        {{ "已报名" | language }}
                        <span>{{ info.reserves }}</span>
                        {{ "人" | language }}
                    </div>

                    <div
                        v-if="!info.isReserve"
                        class="btn-group"
                        @click="goTo(info)"
                    >
                        <div>{{ "立即预约" | language }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="info_wrap">
            <div class="info_title">{{ "活动信息" | language }}</div>
            <div class="info_detail">
                {{ "主讲人" | language }}：{{ info.presenter }}
            </div>
            <div class="info_detail">
                {{ "时间" | language }}：{{ info.startDate }}
            </div>
            <div class="info_detail">
                {{ "地址" | language }}：{{ info.address }}
            </div>
        </div>
        <div class="info_wrap">
            <div class="info_title">{{ "讲座简介" | language }}</div>
            <div class="info_detail">{{ info.talkDesc }}</div>
        </div>
        <div class="info_wrap" style="margin-bottom:80px;">
            <div class="info_title">{{ "主持人简介" | language }}</div>
            <div class="info_detail">{{ info.presenter }}</div>
            <div class="info_detail">{{ info.presenterDesc }}</div>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
export default {
    components: {},
    data() {
        return {
            id: "",
            info: null
        };
    },
    mounted() {
        this.id = this.$route.params.id;
        this.fetchData();
    },
    methods: {
        showDialog() {
            this.$refs.add.edit(this.id);
        },

        async fetchData() {
            let res = await this.$http.get(`/information/eTalk/${this.id}`);
            res.isReserve = dayjs(res.startDate).isBefore(dayjs(new Date()));
            this.info = res;
            console.log(this.info);
        },
        goTo(item) {
            console.log(item._id);
            this.$router.push(`/base/eTalk/detail/order/${item._id}`);
        }
    }
};
</script>

<style lang="less" scoped>
.detail {
    text-align: left;
    .item_title {
        display: flex;
        height: 355px;
        > img {
            width: 360px;
            height: 360px;
            object-fit: cover;
            object-position: center;
        }
        .item_title_content {
            width: 50%;
            height: 282px;
            background: rgba(0, 0, 0, 0.04);
            .item_detail {
                padding: 20px 40px 0 40px;
                .item_detail_title {
                    opacity: 0.9;
                    font-size: 30px;
                    color: #000000;
                    line-height: 40px;
                }
                .item_detail_reserve {
                    margin-top: 10px;
                    color: rgba(0, 0, 0, 0.3);
                    font-size: 16px;
                    > span {
                        font-size: 16px;
                        color: #2c8dfc;
                        line-height: 24px;
                    }
                }
                .btn-group {
                    margin-top: 20px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 150px;
                    height: 40px;
                    background: #3b95fc;
                    border-radius: 2px;
                    > div {
                        font-size: 18px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .info_wrap {
        margin-top: 30px;
        .info_title {
            margin-bottom: 10px;
            font-size: 22px;
            color: rgba(0, 0, 0, 0.9);
            line-height: 32px;
        }
        .info_detail {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.6);
            line-height: 29px;
            white-space: pre;
        }
    }
}
@media screen and (max-width: 960px) {
    .detail {
        .item_title {
            height: auto;
            flex-direction: column;
            > img {
                width: 100%;
                height: auto;
            }
            .item_title_content {
                width: 100%;
                height: 100px;
                .item_detail {
                    .item_detail_title {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
</style>
